import React from "react";
import PropTypes from "prop-types";
import { graphql } from "gatsby";

import { HeaderFilter, SectionPosts } from "components";
import { Layout } from "containers";
import {
  // getPageCategory,
  // getPageConfig,
  getPageFilters,
} from "utils/page";

const TemplatePostCategory = ({
  data: {
    // config,
    postCategories,
    posts,
  },
  location,
}) => {
  const filters = getPageFilters(postCategories, "/blog");
  // const postCategory = getPageCategory(location.pathname);
  // const { header, layout, seo } = getPageConfig(config, postCategory);

  return (
    <Layout seoProps={{ title: "Blog" }}>
      <HeaderFilter filters={filters} heading="Blog" location={location} />
      <SectionPosts posts={posts} />
    </Layout>
  );
};

TemplatePostCategory.propTypes = {
  data: PropTypes.shape({
    // config: PropTypes.shape({
    //   header: PropTypes.shape({
    //     heading: PropTypes.string,
    //   }),
    //   id: PropTypes.string,
    //   seo: PropTypes.shape({
    //     description: PropTypes.string,
    //     title: PropTypes.string,
    //   }),
    // }),
    postCategories: PropTypes.shape({
      group: PropTypes.arrayOf(
        PropTypes.shape({
          category: PropTypes.oneOf(["Books", "Design", "Interview"]),
        })
      ),
    }),
    posts: PropTypes.shape({
      edges: PropTypes.arrayOf(
        PropTypes.shape({
          node: PropTypes.shape({
            data: PropTypes.shape({
              article: PropTypes.oneOfType([
                PropTypes.shape({
                  childMdx: PropTypes.shape({
                    excerpt: PropTypes.string,
                  }),
                }),
                PropTypes.string,
              ]),
              author: PropTypes.string,
              date: PropTypes.string,
              description: PropTypes.string,
              id: PropTypes.string,
              category: PropTypes.oneOf(["Books", "Design", "Interview"]),
              showComments: PropTypes.bool,
              title: PropTypes.string,
              updateDate: PropTypes.string,
            }),
            id: PropTypes.string,
          }),
        })
      ),
    }),
  }),
};

export const postsByCategoryQuery = graphql`
  query PostsByCategory($category: String!) {
    # config: allPagesYaml {
    #   edges {
    #     node {
    #       header {
    #         heading
    #       }
    #       id
    #       seo {
    #         description
    #         title
    #       }
    #     }
    #   }
    # }
    postCategories: allAirtable(
      filter: { data: { isActive: { eq: true } }, table: { eq: "Posts" } }
    ) {
      group(field: data___category) {
        category: fieldValue
      }
    }
    posts: allAirtable(
      filter: {
        data: { isActive: { eq: true }, category: { eq: $category } }
        table: { eq: "Posts" }
      }
      sort: { fields: data___date, order: DESC }
    ) {
      edges {
        node {
          data {
            article {
              childMdx {
                excerpt(pruneLength: 400)
              }
            }
            author
            category
            date(formatString: "MMMM DD, YYYY")
            description
            id
            # showComments
            title
            updateDate(formatString: "MMMM DD, YYYY")
          }
          id
        }
      }
    }
  }
`;

export default TemplatePostCategory;
